.app-root {
  height: 100%;
}

.mri-k-i-loading--overlay {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 64px;
}
.k-i-loading {
  color: #10689d !important; /* keep in sync with LoadingComponent */
}

/**
Styles for saying "IE11 not supported, go use a proper browser
 */

$ie11-prefix: 'ie11';

.#{$ie11-prefix}-hide {
  display: none;
}

.#{$ie11-prefix}-body {
  align-items: center;
  justify-content: center;
  height: 100%;

  & > .#{$ie11-prefix}-message {
    width: 36%;

    & > .#{$ie11-prefix}-header {
      display: flex;
      align-items: center;
      height: 48px;

      & > .circle-icon {
        width: 48px;
        height: 48px;
        padding-right: 8px;
      }

      & > h4.mri-heading--4 {
        font-weight: 300;
      }
    }

    & > p {
      text-align: justify;
      font-weight: 300;
    }

    & > .#{$ie11-prefix}-browser-links {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
}
